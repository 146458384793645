import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector,
} from "@mui/x-data-grid";
import {
  Box,
  Button,
  Container,
  CssBaseline,
  LinearProgress,
  Link,
  Typography,
} from "@mui/material";
import { ArrowBack } from "@mui/icons-material";
import { useHistory, useRouteMatch, useLocation } from "react-router-dom";
import SiccLayout from "./SiccLayout";
import axios from "axios";
import ProgressWithLabel from "../components/ProgressWithLabel";
import { confidenceColorLevels } from "utils/helpers";
import moment from "moment";

const rubyAIUri = process.env.REACT_APP_RUBY_AI_URI;
const rubyConvoUri = process.env.REACT_APP_RUBY_CONVO_URI;

function CustomToolbar() {
  return (
    <GridToolbarContainer
      sx={{
        display: "flex",
        flexDirection: "row",
        gap: 2,
      }}
    >
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
      <GridToolbarExport />
    </GridToolbarContainer>
  );
}

const ConversationList = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const siteId = queryParams.get("siteId");
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0); // DataGrid uses 0-based indexing for pages
  const [pageSize, setPageSize] = useState(10);
  const [rowCount, setRowCount] = useState(0);
  const [conversations, setConversations] = useState([]);

  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: 10,
  });

  // Pull from conversations API
  const getConversations = async () => {
    setLoading(true);
    const searchParams = {
      page: paginationModel.page + 1,
      limit: paginationModel.pageSize,
    };
    try {
      const { data: responseData } = await axios.get(
        `${rubyConvoUri}/conversations?${new URLSearchParams(
          searchParams
        ).toString()}`
      );
      const { pagination, data } = responseData;
      const newData = data.map((item, index) => ({ ...item, id: index }));

      if (siteId) {
        setConversations(newData.filter((item) => item.siteId === siteId));
      } else {
        setConversations(newData);
      }
      setRowCount(pagination.totalItems);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    console.log(page, pageSize, "page");
    getConversations();
  }, [paginationModel]);

  return (
    <SiccLayout>
      <Box>
        <Box
          sx={{
            minHeight: "100vh",
            display: "flex",
            justifyContent: "flex-start",
            width: "100%",
          }}
        >
          <Container>
            <Box sx={{ my: 2 }}>
              <Typography
                variant="h5"
                fontWeight="bold"
                textTransform="uppercase"
              >
                Ruby AI Conversation
              </Typography>
            </Box>
            <Box sx={{ height: 600, width: "100%" }}>
              <DataGrid
                rows={conversations}
                columns={[
                  {
                    headerName: "Sender ID",
                    flex: 1,
                    field: "sender_id",
                    renderCell: (params) => (
                      <Typography
                        color="primary"
                        sx={{
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          history.push(
                            `/conversations/${params.value}`,
                            params.row
                          )
                        }
                      >
                        {params.value}
                      </Typography>
                    ),
                  },
                  {
                    headerName: "First Name",
                    flex: 1,
                    field: "firstName",
                    align: "center",
                    headerAlign: "center",
                  },
                  {
                    headerName: "Last Name",
                    flex: 1,
                    field: "lastName",
                    align: "center",
                    headerAlign: "center",
                  },
                  {
                    headerName: "Site Name",
                    flex: 1,
                    field: "siteName",
                    align: "center",
                    headerAlign: "center",
                  },
                  {
                    headerName: "Site ID",
                    field: "siteId",
                    align: "center",
                    headerAlign: "center",
                  },
                  {
                    headerName: "Last Active",
                    width: 80,
                    field: "activeTime",
                    align: "center",
                    headerAlign: "center",
                    renderCell: (params) => (
                      <Typography
                        variant="caption"
                        sx={{
                          textOverflow: "clip",
                        }}
                      >
                        {moment(params.row?.activeTime * 1000).fromNow()}
                      </Typography>
                    ),
                  },
                  {
                    headerName: "Confidence",
                    flex: 1,
                    field: "averageConfidence",
                    headerAlign: "center",
                    renderCell: (params) => (
                      <Box
                        sx={{
                          width: "100%",
                          display: "flex",
                          flexDirection: "column",
                          gap: 1,
                          textAlign: "center",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "center",
                            width: "100%",
                            gap: 2,
                          }}
                        >
                          <Box
                            sx={{
                              width: "100%",
                              display: "flex",
                              flexDirection: "column",
                              gap: 1,
                              textAlign: "center",
                            }}
                          >
                            <ProgressWithLabel
                              value={params.value * 100}
                              color={confidenceColorLevels(params.value)}
                            />
                          </Box>
                        </Box>
                      </Box>
                    ),
                  },
                  {
                    headerName: "Source",
                    flex: 1,
                    field: "openAIAnswersPercentage",
                    headerAlign: "center",
                    renderCell: (params) => {
                      // console.log(params);
                      return (
                        <Box
                          sx={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "column",
                            gap: 1,
                            textAlign: "center",
                          }}
                        >
                          <ProgressWithLabel
                            value={params.value}
                            label={"OpenAI"}
                          />
                          <ProgressWithLabel
                            value={params.row.rasaIntentAnswersPercentage}
                            label={"Rasa"}
                            color="#FF007F"
                          />
                        </Box>
                      );
                    },
                  },
                ]}
                pagination
                paginationMode="server"
                pageSize={paginationModel.pageSize}
                pageSizeOptions={[5, 10, 20, 100]}
                initialState={{
                  pagination: {
                    paginationModel: paginationModel,
                  },
                }}
                rowCount={rowCount}
                loading={loading}
                sx={{
                  width: "100%",
                  padding: 2,
                }}
                onPaginationModelChange={(newModel) =>
                  setPaginationModel(newModel)
                }
                disableRowSelectionOnClick
              />
            </Box>
          </Container>
        </Box>
      </Box>
    </SiccLayout>
  );
};

export default ConversationList;
