import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import AOS from "aos";
import "aos/dist/aos.css";

const colorBackground = process.env.REACT_APP_BACKGROUND_COLOR;
const foreground = process.env.REACT_APP_FOREGROUND;

export const AboutHighlights = () => {
  const [highlightList, setHighlightList] = useState([]);

  useEffect(() => {
    const itemsString = process.env.REACT_APP_BUSINESS_HIGHLIGHTS || "";
    const itemsArray = itemsString.split(",");
    setHighlightList(itemsArray);
  }, []);

  useEffect(() => {
    AOS.init({
      duration: 900,
    });
  }, []);

  return (
    <Box
      sx={{
        backgroundColor: colorBackground,
        color: foreground,
        height: "auto",
        padding: "16px",
      }}
      data-aos-easing="ease-in-out"
      data-aos-delay="500"
      data-aos="fade-bottom"
    >
      <Box
        className="page-info-right container aos-init aos-animate"
        data-aos-easing="ease-in-out"
        data-aos-delay="600"
        data-aos="fade-top"
      >
        <Box
          className="center col-12"
          data-aos-easing="ease-in-out"
          data-aos-delay="700"
        >
          <Typography
            variant="h4"
            gutterBottom
            className="HeaderUppercase"
            data-aos-easing="ease-in-out"
            data-aos-delay="800"
          >
            Highlights from the Business
          </Typography>
          <Typography
            variant="h5"
            className="about-icons col-12"
            data-aos-easing="ease-in-out"
            data-aos-delay="900"
          >
            <ul>
              {highlightList.map((item, index) => (
                <li key={index}>{item}</li>
              ))}
            </ul>
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};
