import React, { useEffect, useState } from "react";
import {
  Typography,
  Button,
  useMediaQuery,
  IconButton,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { QRDialog } from "./QrDialog";
import { NotificationBell } from "../eComm";
import { useSelector } from "react-redux";
import { Box, Container, Grid } from "@mui/material";
import { RestaurantMenuRounded } from "@material-ui/icons";
import {
  AccountBalanceWalletRounded,
  AccountCircleRounded,
  ReceiptRounded,
} from "@mui/icons-material";

const tagline = process.env.REACT_APP_RESTAURANT_TAGLINE;
const siteId = process.env.REACT_APP_SITE_ID;
const colorBackground = process.env.REACT_APP_BACKGROUND_COLOR;
const buttonColor = process.env.REACT_APP_BUTTON_COLOR;
const buttonText = process.env.REACT_APP_BUTTON_TEXT;
const textColor = process.env.REACT_APP_TEXT_COLOR;
const siteMedSrc = process.env.REACT_APP_MEDIA_SRC;
const enableRuby = parseInt(process.env.REACT_APP_ENABLE_RUBY);
const enableMenu = parseInt(process.env.REACT_APP_ENABLE_MENU);

export const HomeScreen = () => {
  const [openCamera, setOpenCamera] = useState(false);
  const firstName = sessionStorage.getItem("firstName") || null;
  const { user } = useSelector((state) => state.public);
  const history = useHistory();
  const isMobile = useMediaQuery("(max-width:600px)");

  return (
    <>
      <div
        style={{ position: "fixed", top: "20px", right: "20px", zIndex: 100 }}
      >
        {user && <NotificationBell />}
      </div>
      <div
        style={{
          height: "100vh",
          width: "100vw",
          background: `linear-gradient(rgba(255,255,255,.05), rgba(0,0,0,.4)), url(${siteMedSrc}homescreen-bg.png)no-repeat center center`,
          color: textColor,
          position: "fixed",
          top: "0px",
          left: "0px",
          padding: "2rem",
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            marginBottom: "1rem",
          }}
        >
          <Typography>
            <img
              src={siteMedSrc + "logo" + ".svg"}
              style={{ width: "200px" }}
            />
          </Typography>
        </div>
        <Typography
          variant={isMobile ? "p" : "h6"}
          style={{
            textAlign: "center",
            fontWeight: "500",
            marginBottom: "1rem",
            maxWidth: "500px",
            color: textColor,
          }}
        >
          {tagline}
        </Typography>
        {firstName ? (
          <Container maxWidth="sm">
            <Grid
              container
              spacing={3}
              justifyItems={"stretch"}
              sx={{
                margin: "0 !important",
              }}
            >
              {[
                {
                  label: "In-room Ordering",
                  Icon: RestaurantMenuRounded,
                  path: enableMenu === 0 ? "/welcome" : "/menu",
                },
                {
                  label: "My Balance",
                  Icon: AccountBalanceWalletRounded,
                  path: "/",
                },
                {
                  label: "My Transactions",
                  Icon: ReceiptRounded,
                  path: "/transactions",
                },
                {
                  label: "My Profile",
                  Icon: AccountCircleRounded,
                  path: "/profile",
                },
                {
                  label: "Ruby AI",
                  Icon: null,
                  path: "/askme",
                },
              ].map((item, index) =>
                item.label === "Ruby AI" && enableRuby === 1 ? (
                  <Grid
                    item
                    xs={12}
                    md={12}
                    sx={{
                      padding: "8px!important",
                    }}
                  >
                    <button
                      className="MuiButton-root "
                      style={{
                        borderRadius: "15px!important",
                        padding: "1rem 2rem",
                        width: "100%",
                        border: "1px solid",
                        borderColor: colorBackground,
                        background: colorBackground,
                        color: "buttonText",
                        position: "relative",
                        height: "100%",
                        maxHeight: "120px",
                      }}
                      onClick={() => {
                        history.replace("/askme", {
                          from: history.location.pathname,
                        });
                      }}
                    >
                      <div
                        style={{
                          color: "#ffff",
                          fontWeight: "500",
                          fontSize: "1.5rem",
                        }}
                      >
                        Ruby AI
                      </div>
                      <div
                        className={`pulse`}
                        style={{
                          background: "#ffff",
                          borderRadius: 0,
                          height: "110%",
                        }}
                      ></div>
                    </button>
                  </Grid>
                ) : (
                  <Grid
                    key={index}
                    item
                    xs={6}
                    sm={6}
                    sx={{
                      padding: "8px!important",
                    }}
                  >
                    <Button
                      variant="contained"
                      style={{
                        padding: "1rem 1rem",
                        backgroundColor: "rgb(10, 61, 98)",
                        color: textColor,
                        width: "100%",
                        fontSize: "1.2rem",
                        fontWeight: "500",
                        display: "flex",
                        alignItems: "center",
                        height: "100%",
                        maxHeight: "120px",
                      }}
                      onClick={() => {
                        history.push(item.path);
                      }}
                    >
                      <div style={{ marginRight: 2 }}>
                        <item.Icon />
                      </div>
                      <div
                        style={{
                          color: "#ffff",
                          fontWeight: "500",
                          fontSize: "1rem",
                        }}
                      >
                        {item.label}
                      </div>
                    </Button>
                  </Grid>
                )
              )}
            </Grid>
          </Container>
        ) : (
          <>
            <Button
              variant="contained"
              style={{
                borderRadius: "15px",
                marginTop: "1rem",
                padding: "1rem 2rem",
                backgroundColor: buttonColor,
                color: textColor,
                width: "100%",
                maxWidth: "300px",
                fontSize: "1.5rem",
                fontWeight: "500",
              }}
              onClick={() => {
                history.push("/home");
              }}
            >
              Login
            </Button>
          </>
        )}

        {/* {firstName ? (
            <>
              <Button
                variant="contained"
                style={{
                  borderRadius: "15px",
                  marginTop: "1rem",
                  padding: "1rem 2rem",
                  backgroundColor: "rgb(10, 61, 98)",
                  color: textColor,
                  width: "100%",
                  fontSize: "1.5rem",
                  fontWeight: "500",
                }}
                onClick={() => {
                  history.push("/welcome");
                }}
              >
                In-room Dining
              </Button>
            </>
          ) : (
            <>
              <Button
                variant="contained"
                style={{
                  borderRadius: "15px",
                  marginTop: "1rem",
                  padding: "1rem 2rem",
                  backgroundColor: "rgb(10, 61, 98)",
                  color: textColor,
                  width: "100%",
                  fontSize: "1.5rem",
                  fontWeight: "500",
                }}
                onClick={() => {
                  history.push("/home");
                }}
              >
                Login
              </Button>
            </>
          )}
          {siteId == 73 && (
            <button
              className="MuiButton-root "
              style={{
                borderRadius: "15px!important",
                marginTop: "1rem",
                padding: "1rem 2rem",
                width: "100%",
                border: "1px solid",
                borderColor: colorBackground,
                background: colorBackground,
                color: "buttonText",
                position: "relative",
              }}
              onClick={() => {
                history.replace("/askme", {
                  from: history.location.pathname,
                });
              }}
            >
              <div
                style={{
                  color: "#ffff",
                  fontWeight: "500",
                  fontSize: "1.5rem",
                }}
              >
                Ruby AI
              </div>
              <div
                className={`pulse`}
                style={{
                  background: "#ffff",
                  borderRadius: 0,
                  height: "110%",
                }}
              ></div>
            </button>
          )} */}
      </div>
    </>
  );
};
