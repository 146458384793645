import axios from "axios";

const serverId = process.env.REACT_APP_SERVER_ID;
const siteId = process.env.REACT_APP_SITE_ID;
const siteName = process.env.REACT_APP_RESTAURANT_NAME;
const rubyAIUri = process.env.REACT_APP_RUBY_AI_URI;
const rubyConvoUri = process.env.REACT_APP_RUBY_CONVO_URI;

export const updateConversations = async (
  userMessage,
  rasaResponse,
  senderId,
  intentName = "ruby_order_process",
  attempt = 0
) => {
  const lastName = sessionStorage.getItem("lastName") || "AI";
  const firstName = sessionStorage.getItem("firstName") || "SICC";
  const middleName = sessionStorage.getItem("middleName") || "";
  try {
    await axios.put(`${rubyConvoUri}/conversations/save/${senderId}`, {
      userMessage,
      rasaResponse,
      intentName,
    });

    return;
  } catch (error) {
    if (error?.message === "Request failed with status code 404") {
      await axios.put(`${rubyConvoUri}/conversations/add-details/` + senderId, {
        lastName,
        firstName,
        middleName,
        siteId,
        siteName,
      });
    }
    if (attempt < 3) {
      setTimeout(
        () =>
          updateConversations(userMessage, rasaResponse, senderId, attempt++),
        2000
      ); // Retry after 2 seconds
    } else {
      console.error(error, "API call failed after 3 attempts");
    }
    return;
  }
};

export const updateConversationWithProduct = async (
  userMessage,
  rasaResponse,
  productDetails,
  senderId,
  attempt = 1
) => {
  try {
    await axios.put(
      `${rubyConvoUri}/conversations/save-with-product/${senderId}`,
      {
        userMessage,
        rasaResponse,
        productDetails,
      }
    );
    return;
  } catch (error) {
    if (attempt < 3) {
      setTimeout(
        () =>
          updateConversations(userMessage, rasaResponse, senderId, attempt + 1),
        2000
      ); // Retry after 2 seconds
    } else {
      console.error(error, "API call failed after 3 attempts");
    }
    return;
  }
};
