import React, { useEffect } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { CardActionArea } from "@mui/material";
import AOS from "aos";
import "aos/dist/aos.css";

const restaurantName = process.env.REACT_APP_RESTAURANT_NAME;
const colorBackground = process.env.REACT_APP_BACKGROUND_COLOR;
const siteMedSrc = process.env.REACT_APP_MEDIA_SRC;
const businessHoursLine1 = process.env.REACT_APP_BUSINESSHOURS_LINE1;
const businessHoursLine2 = process.env.REACT_APP_BUSINESSHOURS_LINE2;
const businessHoursLine3 = process.env.REACT_APP_BUSINESSHOURS_LINE3;
const businessHoursLine4 = process.env.REACT_APP_BUSINESSHOURS_LINE4;
const textColor = process.env.REACT_APP_TEXT_COLOR;

export default function BusinessCard() {
  useEffect(() => {
    AOS.init({
      duration: 1200,
    });
  }, []);

  return (
    <Card data-aos-easing="ease-in-out" data-aos-delay="0" data-aos="fade-left">
      <CardActionArea
        className="color-scheme"
        sx={{
          cursor: "default !important",
          pointerEvents: "none",
          backgroundColor: colorBackground,
        }}
      >
        <CardMedia
          component="img"
          height="140px"
          image={siteMedSrc + "business-hours" + ".webp"}
          title={`${restaurantName} Business Card`}
          alt={`${restaurantName} Store Hours`}
          width="100%"
          loading="lazy"
        />
        <CardContent style={{ color: textColor, height: "200px" }}>
          <Typography variant="h4" component="div">
            Store Hours
          </Typography>
          <Typography variant="h6" component="div">
            {businessHoursLine1}
            <br></br>
            {businessHoursLine2}
            <br></br>
            {businessHoursLine3}
            <br></br>
            {businessHoursLine4}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
