import {
  INIT_ORDER,
  SET_ORDER_QTY,
  SET_CANCEL_ORDER,
  SET_PRE_CANCEL_ORDER,
  SET_END_ORDER_PROCESS,
  SET_ORDER_SUGGESTION,
  SET_ORDER_SUGGESTION_QTY,
  ORDER_WITH_MODIFIERS,
  RESET_ORDER_AI,
  SET_STAFF_TIP,
  SET_ON_TIP_PROCESS,
} from "./constant";

const initialState = {
  orderStatus: null, //  "order" | "modify" | "quantity" | "suggest" | "anythingelse"
  cancelStatus: null,
  itemDetails: {},
  message: "",
};

export default function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case INIT_ORDER:
      state.isOnOrderMode = true;
      state.orderStatus = "order";
      state.itemDetails = payload.productDetails;
      // TODO ADD CONDITION FOR ITEMS WITH MODIFIERS
      state.message = payload.message;
      return state;
    case ORDER_WITH_MODIFIERS:
      state.isOnOrderMode = true;
      state.orderStatus = "order-modifier";
      state.itemDetails = payload.productDetails;
      // TODO ADD CONDITION FOR ITEMS WITH MODIFIERS
      state.message = payload.message;
      return state;
    case SET_ORDER_QTY:
      return {
        ...state,
        orderStatus: "quantity",
        itemDetails: { ...state.itemDetails, quantity: payload.quantity },
        message: payload.message,
      };
    case SET_ORDER_SUGGESTION:
      state.isOnOrderMode = true;
      state.orderStatus = "suggest";
      state.itemDetails = payload;
      state.message = `How many ${payload?.ItemName} would you like to order?`;
      return state;
    case SET_ORDER_SUGGESTION_QTY:
      state.isOnOrderMode = true;
      state.orderStatus = "anythingelse";
      state.itemDetails = { ...state.itemDetails, quantity: payload.quantity };
      state.message = payload?.message;
      return state;
    case SET_STAFF_TIP:
      state.isOnOrderMode = false;
      state.orderStatus = "staff-tip";
      state.message = payload?.message;
      return state;
    case SET_ON_TIP_PROCESS:
      state.isOnOrderMode = false;
      state.orderStatus = "tip-process";
      state.message = payload?.message;
      return state;
    case SET_END_ORDER_PROCESS:
      state.isOnOrderMode = false;
      state.orderStatus = null;
      state.itemDetails = {};
      state.message = payload.message;
      return state;
    case SET_PRE_CANCEL_ORDER:
      return {
        ...state,
        cancelStatus: "pre-cancel",
        message: `I didn't get that, please try again one more time.`,
      };
    case SET_CANCEL_ORDER:
      const productName = state.itemDetails?.ItemName;
      return {
        orderStatus: null,
        cancelStatus: null,
        itemDetails: {},
        message: `Sorry your order ${productName} has been cancelled, try to order again.`,
      };
    case RESET_ORDER_AI:
      return {
        orderStatus: null, //  "order" | "modify" | "quantity" | "suggest" | "anythingelse"
        cancelStatus: null,
        itemDetails: {},
        message: "",
      };

    default:
      return state;
  }
}
