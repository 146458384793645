import React from "react";
import { AppBar, Box, Toolbar } from "@mui/material";
import { LowerNav } from "../navigation/NavBar";

const restaurantName = process.env.REACT_APP_RESTAURANT_NAME;
const siteMedSrc = process.env.REACT_APP_MEDIA_SRC;
const logoWidth = process.env.REACT_APP_LOGO_WIDTH;

const EKioskNav = () => {
  return (
    <AppBar
      position="fixed"
      sx={{
        position: "sticky",
        top: 0,
        backgroundColor: (theme) => theme.palette.background.default,
        zIndex: 10,
      }}
    >
      <Toolbar
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
          maxWidth: "1080px",
          mx: "auto",
          padding: "0.35rem 1rem",
        }}
        id="headerLogoSticky"
      >
        <Box
          id="logo-left"
          sx={{
            display: "flex",
            alignItems: "center",
            padding: "0.35rem 0",
            textAlign: "center",
          }}
        >
          <img
            className="App-logo"
            src={`${siteMedSrc}logo.webp`}
            alt={restaurantName}
            style={{
              width: logoWidth,
              padding: ".25rem 0",
              transform: "scale(1.45)",
              transformOrigin: "left center",
            }}
          />
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            mt: "1em",
            padding: "0",
            alignItems: "center",
            color: (theme) =>
              theme.palette.mode === "dark" ? "grey.300" : "grey.800",
          }}
        >
          <LowerNav />
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default EKioskNav;
