import React, { useState, useRef, useEffect } from "react";
import { Link, animateScroll } from "react-scroll";
import Tabs from "@mui/material/Tabs";
import Box from "@mui/material/Box";
import Breadcrumbs from "./Breadcrumbs";
import AppBar from "@mui/material/AppBar";
import { StyledEngineProvider } from "@mui/material/styles";
import "../../../assets/css/custom.css";
import SLBreadcrumbs from "./SLBreadcrumbs";

const enableRevenueCenter = parseInt(
  process.env.REACT_APP_ENABLE_REVENUE_CENTER
);

const enableKiosk = process.env.REACT_APP_ENABLE_EKIOSK;
const siteMedSrc = process.env.REACT_APP_MEDIA_SRC;

function StickyCategoriesNav({ categories }) {
  const [showScrollButtons, setShowScrollButtons] = useState(false);
  const tabsRef = useRef(null);

  const handleScroll = () => {
    if (tabsRef.current) {
      const { scrollWidth, clientWidth, scrollLeft } = tabsRef.current;

      // Check if scroll buttons should be shown
      const shouldShowScrollButtons = scrollWidth > clientWidth;

      setShowScrollButtons(shouldShowScrollButtons);
    }
  };

  useEffect(() => {
    // Add event listener for scroll
    const handleResize = () => {
      handleScroll();
    };

    window.addEventListener("resize", handleResize);
    handleScroll(); // Initial check

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div>
      {enableKiosk === "1" ? (
        <AppBar
          className="eKioskCategoriesNav item-list"
          position="fixed"
          sx={{
            zIndex: "999",
            top: "110px",
            paddingTop: "20px",
            paddingBottom: "10px",
          }}
        >
          <Box>
            <Tabs variant="scrollable" className="item-list" width="250px">
              {categories.map((item) => (
                <li key={item.DepartmentID}>
                  <Link
                    to={item.DepartmentID}
                    smooth={true}
                    offset={-450}
                    duration={200}
                    spy={true}
                  >
                    <div className="item-image-container">
                      <img
                        className="button-click"
                        src={siteMedSrc + item.DepartmentID + ".webp"}
                        style={{
                          width: "250px",
                          height: "auto",
                        }}
                        // alt={item.DepartmentDescription}
                      />
                      <div
                        style={{
                          width: "250px",
                          height: "auto",
                        }}
                      >
                        <h4
                          style={{
                            paddingTop: "10px",
                            textAlign: "center",
                            color: "black",
                            display: "-webkit-box",
                            "-webkit-line-clamp": "2",
                            overflow: "hidden",
                            "-webkit-box-orient": "vertical",
                          }}
                        >
                          {item.DepartmentDescription}
                        </h4>
                      </div>
                    </div>
                  </Link>
                </li>
              ))}
            </Tabs>
          </Box>
        </AppBar>
      ) : (
        <div className="stickyCategoriesNav">
          <Box>
            {/* {enableRevenueCenter === 1 ? (
              <SLBreadcrumbs categories={categories} />
            ) : (
              <Breadcrumbs categories={categories} />
            )} */}
            <Tabs variant="scrollable">
              {categories.map((item) => (
                <li
                  scrollButtons={showScrollButtons ? "auto" : "off"}
                  sx={{
                    overflowX: "auto",
                  }}
                  ref={tabsRef}
                  key={item.DepartmentID}
                >
                  <Link
                    to={item.DepartmentID}
                    smooth={true}
                    offset={-200}
                    duration={200}
                    spy={true}
                  >
                    {item.DepartmentDescription}
                  </Link>
                </li>
              ))}
            </Tabs>
          </Box>
        </div>
      )}
    </div>
  );
}

export default StickyCategoriesNav;
