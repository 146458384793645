import React, { useState, useEffect } from "react";
import {
  Grid,
  Button,
  Typography,
  Divider,
  useMediaQuery,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Checkbox,
  FormControlLabel,
  FormControl,
  FormGroup,
  Select,
  MenuItem,
  InputLabel,
} from "@material-ui/core";
import parseHtml from "html-react-parser";
import { useSelector, useDispatch } from "react-redux";
import { useRouteMatch } from "react-router-dom";
import FavoriteIcon from "@material-ui/icons/Favorite";
import { useHistory } from "react-router-dom";
import { Close } from "@material-ui/icons";
import { toast } from "react-toastify";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import FavoriteBorderIcon from "@material-ui/icons/FavoriteBorder";
import Alert from "@material-ui/lab/Alert";
import { Box } from "@mui/material";
import { useCookies } from "react-cookie";
import { filterMenuByDietary, numberWithCommas } from "../utils/helpers";
import { makeStyles } from "@material-ui/core/styles";
import { updateConversationWithProduct } from "utils/services";
import { memo } from "react";
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    overflow: "hidden",
    backgroundColor: theme.palette.background.paper,
  },
  gridList: {
    flexWrap: "wrap",
    height: "auto",
    gridTemplateColumns: "fit-content(300px) fit-content(300px) 1fr",
    transform: "translateZ(0)",
    padding: "0rem 10px",
    backgroundColor: "white",
    marginRight: "auto !important",
    marginLeft: "auto !important",
    justifyContent: "center",
  },
  toolbar: theme.mixins.toolbar,
  dialogTitleBar: {
    margin: 0,
    padding: theme.spacing(2),
  },
  close: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));
const siteImgSrc = process.env.REACT_APP_IMAGE_SRC;
const siteMedSrc = process.env.REACT_APP_MEDIA_SRC;
const modifier = {
  ItemModifierID: "A4B80ED9-4CD1-4956-A863-3501028626A7",
  ParentID: "3B82CD9B-0351-EB11-A4EA-CCB0DAF34ED2",
  ModifierIndex: 0,
  ItemID: "A333295D-0151-EB11-A4EA-CCB0DAF34ED2",
  ItemName: "MOD-DRESS-FRENCH",
  ItemDescription: "French",
  ModifierDescription: "",
  ModifierMaxSelect: 0,
  ModifierMinSelect: 0,
  UseModifierMinSelect: 0,
  UseModifierMaxSelect: 0,
  AllowModifierMaxBypass: 0,
  IsModifierGroup: 0,
  ReceiptDesc: "French",
  DefaultPrice: 0,
  ParentReceiptDesc: "Dressing",
};

const ProductDetailsDialog = ({
  product,
  open,
  handleClose,
  modifiers,
  setModifiers,
}) => {
  const match = useRouteMatch();
  const history = useHistory();
  const classes = useStyles();
  const [quantity, setQuantity] = useState(1);
  const [isFavorite, setIsFavorite] = useState(false);
  const dispatch = useDispatch();
  const isMobile = useMediaQuery("(max-width: 600px)");
  const { cartItems } = useSelector((state) => state.cart);
  const [restaurantFavorites, setRestaurantFavorites] = useState([]);
  const [required, setRequired] = useState([]);
  const [isHealthy, setIsHealthy] = useState(true);
  const [cookieSIC, setCookieSIC] = useCookies([]);
  const senderId = localStorage.getItem("sender_id") || "default";
  const dietaryDetails = {
    dietaryPreferences: [cookieSIC?.formData?.dietPref] || [],
    allergenRestrictions: cookieSIC?.allergens || [],
    otherPreferences: cookieSIC?.formData,
  };
  const healthy = filterMenuByDietary(
    product,
    dietaryDetails.dietaryPreferences
  );

  var decodeEntities = (function () {
    // this prevents any overhead from creating the object each time
    var element = document.createElement("div");

    function decodeHTMLEntities(str) {
      if (str && typeof str === "string") {
        // strip script/html tags
        str = str.replace(/<script[^>]*>([\S\s]*?)<\/script>/gim, "");
        str = str.replace(/<\/?\w(?:[^"'>]|"[^"]*"|'[^']*')*>/gim, "");
        element.innerHTML = str;
        str = element.textContent;
        element.textContent = "";
      }

      return str;
    }

    return decodeHTMLEntities;
  })();
  const handleCheckout = () => {
    if (required.length > 0) {
      document.getElementById(required[0]).scrollIntoView();
    }
    if (required.length == 0) {
      //console.log(cartItems, product, "match");
      const findProduct = cartItems.find(
        (item) =>
          item.ItemID === product.ItemID &&
          item.modifiers &&
          item.modifiers.every((modifier) =>
            product?.modifiers?.find(
              (mod) => mod.ItemName === modifier.ItemName
            )
          ) &&
          (item?.modifiers?.length === product?.modifiers?.length ||
            !item.modifiers ||
            (!product?.modifiers?.length && !item?.modifiers?.length))
      );
      if (!findProduct) {
        delete product.modifiers;
        dispatch({
          type: "SET_CART",
          payload: [
            ...cartItems,
            {
              ...product,
              quantity: quantity,
              modifiers: modifiers,
              comments: "",
              expiration: Date.now() + 86400000,
            },
          ],
        });
        updateConversationWithProduct(
          "(User Select from the Dialog menu)",
          `${quantity} ${product?.ItemName} has been added to cart, Is there anything else that you want to order?`,
          {
            ...product,
            quantity: quantity,
            modifiers: modifiers,
          },
          senderId
        );
      } else {
        let newCartItems = cartItems.map((item) => {
          if (
            //item.ItemID === findproduct.ItemID &&
            (item.modifiers.every((modifier) =>
              product?.modifiers?.find(
                (mod) => mod.ItemName === modifier.ItemName
              )
            ) &&
              item.modifiers.length === product?.modifiers?.length) || //item.ItemID === findproduct.ItemID &&
            (item.modifiers.length === 0 && product?.modifiers?.length === 0)
          ) {
            delete item.modifiers;
            return {
              ...item,
              quantity: item.quantity + quantity,
              comments: "",
            };
          }
          return item;
        });
        console.log(newCartItems, "ite");
        dispatch({
          type: "SET_CART",
          payload: newCartItems,
        });
        updateConversationWithProduct(
          "(User Select from the Dialog menu)",
          `${quantity} ${product?.ItemName} has been added to cart, Is there anything else that you want to order?`,
          newCartItems,
          senderId
        );
      }

      dispatch({
        type: "SET_ORDER_SUGGESTION_QTY",
        payload: {
          quantity,
          message: `${quantity} ${product?.ItemName} has been added to cart, Is there anything else that you want to order?`,
        },
      });

      handleClose();
    }
  };
  return (
    <>
      <Dialog
        open={open}
        onClose={() => handleClose}
        fullWidth
        maxWidth={"md"}
        fullScreen={isMobile}
        style={{ borderRadius: "0px" }}
        scroll="paper"
      >
        {product ? (
          <Grid container className="item-container">
            <Grid item style={{ position: "relative" }}>
              <Box>
                <img
                  alt={product.ItemDescription}
                  title={product.ItemDescription}
                  loading="lazy"
                  src={
                    product.FileName && product.FileName.length > 0
                      ? siteImgSrc + encodeURIComponent(product.FileName)
                      : siteMedSrc + "empty-img.png"
                  }
                  style={{
                    maxWidth: "100%",
                    maxHeight: "100%",
                    objectFit: "cover",
                  }}
                />
              </Box>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "flex-start",
                  margin: "1rem 0rem",
                  position: "absolute",
                  right: "0px",
                  top: "300px",
                }}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div>
                    {!isFavorite ? (
                      <IconButton
                        onClick={() => {
                          localStorage.setItem(
                            `favorites_${match.params.restaurantId}`,
                            JSON.stringify([product, ...restaurantFavorites])
                          );
                          toast.success("Product Saved to Favorites");
                          setIsFavorite(true);
                          history.goBack();
                        }}
                      >
                        <FavoriteBorderIcon
                          style={{ display: "none", color: "#fff" }}
                        />
                      </IconButton>
                    ) : (
                      <IconButton
                        onClick={() => {
                          const removedFromFavorites =
                            restaurantFavorites.filter(
                              (fav) => fav.id !== match.params.productId
                            );
                          localStorage.setItem(
                            `favorites_${match.params.restaurantId}`,
                            JSON.stringify(removedFromFavorites)
                          );
                          toast.success("Product Removed From Favorites");
                          setIsFavorite(false);
                          history.goBack();
                        }}
                      >
                        <FavoriteIcon color="secondary" />
                      </IconButton>
                    )}
                  </div>
                </div>
              </div>
            </Grid>
            <Grid item style={{ position: isMobile ? "" : "relative" }}>
              <DialogTitle className={classes.dialogTitleBar}>
                <Typography
                  variant="h3"
                  style={{ fontWeight: "800", marginBottom: "1rem" }}
                >
                  {product.ItemName}
                </Typography>
                <Typography variant="h6" component="h4">
                  $ {numberWithCommas(product.DefaultPrice / 100)}
                </Typography>
                <IconButton
                  onClick={() => history.goBack()}
                  className={classes.close}
                >
                  <Close />
                </IconButton>
              </DialogTitle>
              <DialogContent>
                <Grid container>
                  <Grid item xs={12}>
                    <Grid container justify="space-between">
                      <Grid item>
                        <Typography variant="subtitle1">
                          {product.ItemDescription}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={6}></Grid>
                </Grid>

                <div style={{ margin: "1rem 0rem" }}>
                  {product.modifiers
                    ? product.modifiers.map((mod, i) => {
                        return (
                          <div>
                            {mod.ModifierMaxSelect == 1 ? (
                              <FormControl fullWidth>
                                <InputLabel>
                                  {mod.ModifierMinSelect > 0
                                    ? parseHtml(
                                        mod.ModifierDescription +
                                          " <span>*</span>"
                                      )
                                    : mod.ModifierDescription}
                                </InputLabel>

                                <Select
                                  id={mod.ItemID}
                                  autoWidth="true"
                                  onChange={(e) => {
                                    // Handle Required
                                    if (mod.ModifierMinSelect > 0) {
                                      if (e.target.value !== "") {
                                        setRequired(
                                          required.filter(
                                            (i) => i !== e.target.value.ParentID
                                          )
                                        );
                                      } else {
                                        setRequired((required) => [
                                          ...required,
                                          e.nativeEvent.target.id,
                                        ]);
                                      }
                                    }
                                    // Add mods
                                    setModifiers(
                                      product?.modifiers?.filter(
                                        (i) =>
                                          i.ParentID !== e.target.value.ParentID
                                      )
                                    );

                                    setModifiers((modifiers) => [
                                      ...modifiers,
                                      e.target.value,
                                    ]);
                                  }}
                                >
                                  <MenuItem value="" id={mod.ItemID}>
                                    <em>--Select --</em>
                                  </MenuItem>
                                  {mod.sub_mods
                                    ? mod.sub_mods.map((sub_mod) => {
                                        return (
                                          <MenuItem value={sub_mod}>
                                            {sub_mod.DefaultPrice > 0
                                              ? `${
                                                  sub_mod.ModifierDescription
                                                    ? sub_mod.ModifierDescription
                                                    : sub_mod.ItemDescription
                                                } + $ ${numberWithCommas(
                                                  sub_mod.DefaultPrice / 100
                                                )}`
                                              : `${
                                                  sub_mod.ModifierDescription
                                                    ? sub_mod.ModifierDescription
                                                    : sub_mod.ItemDescription
                                                }`}
                                          </MenuItem>
                                        );
                                      })
                                    : null}
                                </Select>
                              </FormControl>
                            ) : (
                              <p>
                                <FormGroup style={{ "margin-top": "5%" }}>
                                  <InputLabel>
                                    {mod.ModifierMinSelect > 0
                                      ? parseHtml(
                                          mod.ModifierDescription +
                                            " <span>*</span>"
                                        )
                                      : mod.ModifierDescription}
                                  </InputLabel>
                                  {mod.sub_mods
                                    ? mod.sub_mods.map((sub_mod) => {
                                        return (
                                          <FormControlLabel
                                            value=""
                                            control={
                                              <Checkbox
                                                onChange={(e) => {
                                                  const selectedMods =
                                                    product?.modifiers?.filter(
                                                      (m) =>
                                                        m.ParentID ==
                                                        sub_mod.ParentID
                                                    );

                                                  //Handle Required
                                                  if (
                                                    mod.ModifierMinSelect > 0
                                                  ) {
                                                    if (e.target.checked) {
                                                      if (
                                                        selectedMods &&
                                                        selectedMods.length ==
                                                          mod.ModifierMinSelect -
                                                            1
                                                      ) {
                                                        setRequired(
                                                          required.filter(
                                                            (i) =>
                                                              i !==
                                                              sub_mod.ParentID
                                                          )
                                                        );
                                                      }
                                                    } else {
                                                      setRequired(
                                                        (required) => [
                                                          ...required,
                                                          sub_mod.ParentID,
                                                        ]
                                                      );
                                                    }
                                                  }
                                                  //Add mods
                                                  if (e.target.checked) {
                                                    if (
                                                      mod.ModifierMaxSelect > 0
                                                    ) {
                                                      if (
                                                        selectedMods &&
                                                        selectedMods.length <=
                                                          mod.ModifierMaxSelect -
                                                            1
                                                      ) {
                                                        setModifiers([
                                                          ...modifiers,
                                                          sub_mod,
                                                        ]);
                                                      }
                                                    } else {
                                                      setModifiers([
                                                        ...modifiers,
                                                        sub_mod,
                                                      ]);
                                                    }
                                                  } else {
                                                    setModifiers(
                                                      product?.modifiers?.filter(
                                                        (i) =>
                                                          i.ItemName !==
                                                          sub_mod.ItemName
                                                      )
                                                    );
                                                  }
                                                }}
                                                checked={modifiers.some(
                                                  (m) =>
                                                    m.ItemName ===
                                                    sub_mod.ItemName
                                                )}
                                              />
                                            }
                                            label={
                                              sub_mod.DefaultPrice > 0
                                                ? `${
                                                    sub_mod.ModifierDescription
                                                      ? sub_mod.ModifierDescription
                                                      : sub_mod.ItemDescription
                                                  } + $ ${numberWithCommas(
                                                    sub_mod.DefaultPrice / 100
                                                  )}`
                                                : `${
                                                    sub_mod.ModifierDescription
                                                      ? sub_mod.ModifierDescription
                                                      : sub_mod.ItemDescription
                                                  }`
                                            }
                                          />
                                        );
                                      })
                                    : null}
                                </FormGroup>
                              </p>
                            )}
                          </div>
                        );
                      })
                    : null}
                </div>
                {required.length > 0 ? (
                  <Alert severity="warning">
                    Field(s) with "*" is required.
                  </Alert>
                ) : null}
                <Grid container spacing={1}>
                  <Grid item xs={6}>
                    <div style={{ border: "2px solid black", height: "100%" }}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <IconButton
                          onClick={() => {
                            if (quantity > 1) {
                              setQuantity(quantity - 1);
                            }
                          }}
                        >
                          <RemoveIcon />
                        </IconButton>
                        <div style={{ margin: "0rem 1rem" }}>{quantity}</div>
                        <IconButton onClick={() => setQuantity(quantity + 1)}>
                          <AddIcon />
                        </IconButton>
                      </div>
                    </div>
                  </Grid>

                  <Grid item xs={6}>
                    <Button
                      style={{
                        height: "100%",
                        borderRadius: "0px",
                        textTransform: "none",
                      }}
                      color="primary"
                      variant="contained"
                      fullWidth
                      onClick={handleCheckout}
                    >
                      ADD TO CHECK
                    </Button>
                  </Grid>
                </Grid>
                <Divider style={{ "margin-top": "5%" }} variant="middle" />
                <Grid container>
                  <Grid item xs={12}>
                    <Grid container justify="space-between">
                      <Grid item>
                        <Typography variant="subtitle1">
                          {product.ItemMessages
                            ? parseHtml(
                                decodeEntities(
                                  product.ItemMessages.replace(
                                    /table/g,
                                    "table style='border: 1px solid #000'"
                                  ).replace(
                                    /td/g,
                                    "td style='border: 1px solid #000'"
                                  )
                                )
                              )
                            : ""}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={6}></Grid>
                </Grid>
                {/* </Paper> */}
              </DialogContent>
              {/* {!healthy && (
                <Box
                  sx={{
                    display: "grid",
                    placeItems: "center",
                    width: "100%",
                  }}
                >
                  <Alert severity="warning">
                    This item can negatively affect your health.
                  </Alert>
                </Box>
              )} */}
              <div className={classes.toolbar} />
            </Grid>
          </Grid>
        ) : null}
      </Dialog>
    </>
  );
};

export default memo(ProductDetailsDialog);
