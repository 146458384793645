import React, { useEffect, useState } from "react";
import { Route, Switch, Redirect, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import ReactGA from "react-ga";
import axios from "axios";

import { useMediaQuery } from "@material-ui/core";

import {
  About,
  Account,
  ChangePassword,
  Checkout,
  CheckoutSL,
  Contact,
  EloyaltyForgotPW,
  EloyaltyResetPW,
  Favorites,
  Homepage,
  Login,
  LoginCode,
  Main,
  MainNavigation,
  MeetingRooms,
  Mobile,
  NotMe,
  OrderNow,
  PhotoGallery,
  PrivacyPolicy,
  ProductDetails,
  Register,
  Restaurant,
  RestaurantSL,
  RestaurantCategory,
  RestaurantHome,
  RestaurantLayout,
  RestaurantMenu,
  RestaurantSearch,
  Rewards,
  SelectAddress,
  StatusScreen,
  Team,
  TermsandCondition,
  TimeComponent,
  UpdateCode,
  Worldpay,
  Welcome,
  WelcomeQr,
  ProductDetailsSL,
} from "./features/eComm";
import { DeliveryMap } from "./features/eComm/DeliveryMapV2";
import { Loader } from "./components";
import { ToastContainer } from "react-toastify";
// Other necessary imports...
import { HomeScreen } from "./features/public/HomeScreen";
import {
  HomeScreenSLEKiosk,
  HomepageLandingSLEKiosk,
  HomepageLanding,
} from "./features/public";
import { SignInSL, LogInSL } from "./features/eComm/components/pages/homepage";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
//import { useAlan } from "./hooks/useAlan";
import { NewLayout, NewCategory } from "./Sandbox";
import { CartPage } from "./features/eComm/cart/CartPage";
import { PaymentHandler } from "./features/paymentHandler/";
import { RewardsAsPayment } from "./features/eComm/rewards";
import "react-calendar/dist/Calendar.css";
import "./App.scss";

import { removeExpiredItems, setCart } from "./redux/cartReducer/actions";

import Askme from "features/public/AskMe";
import Saphire from "features/eComm/sicc/Saphire";
import ConversationList from "features/eComm/sicc/ConversationList";
import ConversationDetails from "features/eComm/sicc/ConversationDetails";
import { Transactions } from "features/eComm/pages/Transactions";
import { Profile } from "features/eComm/pages/Profile";
import { createBrowserHistory } from "history";

//import { HomeDriver, NavDriver, Drive } from "./features/driver";

import { Signin } from "./features/public/Signin";
import { ClientRegister } from "./features/public/ClientRegistration";

// Environment variables
const serverId = process.env.REACT_APP_SERVER_ID;
const siteId = process.env.REACT_APP_SITE_ID;
const siteName = process.env.REACT_APP_SITE_NAME;
const enableRevenueCenter = parseInt(
  process.env.REACT_APP_ENABLE_REVENUE_CENTER
);
const enableMenu = parseInt(process.env.REACT_APP_ENABLE_MENU);
const loginPage = process.env.REACT_APP_LOGIN_PAGE;
const enableSL = parseInt(process.env.REACT_APP_ENABLE_SL);
const enableKiosk = parseInt(process.env.REACT_APP_ENABLE_EKIOSK);
const apiBaseURL = process.env.REACT_APP_API_BASE_URL;

function App() {
  // Hooks and state variables
  const history = useHistory();
  const dispatch = useDispatch();
  const { user, loading } = useSelector((state) => state.public);
  const [deviceToken, setDeviceToken] = useState("");
  const query = enableKiosk === 0 ? "(max-width: 600px)" : "(max-width: 0px)";
  const isMobile = useMediaQuery("(max-width:600px)");

  // useEffects and other logic...
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  useEffect(() => {
    const url =
      apiBaseURL + "/items/sync-to-menu-info/" + serverId + "/" + siteId;
    axios
      .get(url)
      .then((response) => {
        const data = response.data;
      })
      .catch((error) => {
        return;
      });

    const paymentId = localStorage.getItem("pid");

    if (paymentId) {
      const paymentStatusUrl =
        apiBaseURL + "/postpayment/verifypayment/" + siteId + "/" + paymentId;
      axios
        .get(paymentStatusUrl)
        .then((response) => {
          const data = response.data;

          // If payment is approved delete the cart items
          if (data.Status === "Approved") {
            dispatch(setCart([]));
            localStorage.removeItem("pid");
          }
        })
        .catch((error) => {
          return;
        });
    }

    //Clear the cart if order is paid
    if (parseInt(sessionStorage.getItem("order_id")) > 0) {
      const url = `${apiBaseURL}/postpayment/getOrder/${siteId}/${sessionStorage.getItem(
        "order_id"
      )}?server_id=${serverId}`;
      axios
        .get(url)
        .then((response) => {
          const data = response.data;
          if (parseInt(data.IsSync) === 1) {
            dispatch(setCart([]));
            sessionStorage.removeItem("order_id");
            localStorage.clear();
          }
        })
        .catch((error) => {
          return;
        });
    }
  }, []);

  useEffect(() => {
    dispatch(removeExpiredItems());
    const intervalId = setInterval(() => {
      dispatch(removeExpiredItems());
    }, 60000);
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    //disable for now
    // var shouldReload = true;
    // var sourcePWA = new EventSource(
    //   apiBaseURL + "/pwa/check_update"
    // );
    // sourcePWA.onmessage = function (event) {
    //   var u_items = JSON.parse(event.data);
    //   if (u_items[0].time != "") {
    //     var currentUpdateTime = sessionStorage.getItem("pwa_update_date_time");
    //     if (currentUpdateTime !== u_items[0].time) {
    //       caches.delete("appV1");
    //       sessionStorage.setItem("pwa_update_date_time", u_items[0].time);
    //       if (shouldReload) {
    //         shouldReload = false; // Prevent continuous reload
    //         window.location.reload();
    //       }
    //     }
    //   }
    // };
    // sourcePWA.onerror = function (error) {
    //   console.error("EventSource failed:", error);
    //   sourcePWA.close();
    // };
    // window.addEventListener("beforeunload", function () {
    //   sourcePWA.close(); // Close EventSource when the page is closed or reloaded
    // });
  }, []);

  // Return JSX with the correct components and props
  return (
    <>
      {/* <LocationListener /> */}
      <Loader open={loading} />
      <Switch>
        {enableSL === 0 && (
          <Route
            exact
            path="/"
            component={
              enableRevenueCenter === 1
                ? Welcome
                : !isMobile
                  ? HomepageLanding
                  : Mobile
            }
          />
        )}

        {enableSL === 1 && (
          <Route
            exact
            path="/"
            component={
              enableKiosk === 1
                ? HomeScreenSLEKiosk
                : loginPage === "Signin1"
                  ? HomepageLandingSLEKiosk
                  : LogInSL
            }
          />
        )}
        <Route
          exact
          path="/sign-in-sl"
          component={loginPage === "Signin1" ? SignInSL : LogInSL}
        />

        <Route exact path="/logincode" component={LoginCode} />
        <Route exact path="/sandbox" component={NewLayout} />
        <Route exact path="/sand-cat" component={NewCategory} />
        <Route exact path="/sand-time" component={TimeComponent} />
        <Route exact path="/updatecode" component={UpdateCode} />
        <Route exact path="/notme" component={NotMe} />

        {/* {user?.userType === "ADMIN" && <AdminRoutes />}
        {user?.userType === "DRIVER" && (
          <>
            <NavDriver />
            <Route exact path={"/home"} component={HomeDriver} />
            <Route exact path="/drive/:orderId" component={Drive} />
          </>
        )} */}
        <Route exact path="/homepage/" component={Homepage} />
        <Route exact path="/search/" component={RestaurantSearch} />
        <Route exact path="/payment-handler" component={PaymentHandler} />
        <Route exact path="/cart/" component={CartPage} />
        <Route exact path="/sign-in" component={Signin} />
        <Route exact path="/registration" component={ClientRegister} />

        {parseInt(siteId) === 73 &&
          enableSL === 1 && [
            <Route
              key="root"
              exact
              path="/"
              component={!isMobile ? HomeScreen : Mobile}
            />,
            <Route key="askme" exact path="/askme" component={Askme} />,
            <Route key="ruby" exact path="/ruby" component={Saphire} />,

            <Route
              key="conversations"
              exact
              path="/conversations"
              component={ConversationList}
            />,
            <Route
              key="conversationDetails"
              exact
              path="/conversations/:senderId"
              component={ConversationDetails}
            />,
          ]}
        <Route exact path="/sandbox" component={NewLayout} />
        <Route exact path="/sand-cat" component={NewCategory} />
        <Route exact path="/sand-time" component={TimeComponent} />

        <Route exact path="/search/" component={RestaurantSearch} />
        <Route exact path="/payment-handler" component={PaymentHandler} />
        <Route exact path="/cart/" component={CartPage} />

        <Route exact path="/welcome" component={Welcome} />
        <Route exact path="/transactions" component={Transactions} />
        <Route exact path="/profile" component={Profile} />

        <Route exact path="/about" component={About} />
        <Route exact path="/ordernow" component={OrderNow} />
        <Route exact path="/contact" component={Contact} />
        <Route exact path="/meeting-rooms" component={MeetingRooms} />
        <Route exact path="/photo-gallery" component={PhotoGallery} />
        <Route exact path="/privacy-policy" component={PrivacyPolicy} />
        <Route exact path="/Team" component={Team} />
        <Route exact path="/mobile" component={Mobile} />
        <Route
          exact
          path="/terms-and-conditions"
          component={TermsandCondition}
        />

        <Route exact path="/login" component={Login} />
        <Route exact path="/activate/:activationCode" component={Login} />
        <Route exact path="/register" component={Register} />
        <Route exact path="/account" component={Account} />
        <Route exact path="/change-password" component={ChangePassword} />
        <Route exact path="/forgot-password" component={EloyaltyForgotPW} />
        <Route
          exact
          path="/reset-password/:resetToken"
          component={EloyaltyResetPW}
        />
        <Route exact path="/rewards" component={Rewards} />

        <Route path="/checkout" component={Checkout} />
        <Route path="/checkout-sl" component={CheckoutSL} />

        <Route
          exact
          path="/order-history"
          component={() => {
            return (
              <>
                <MainNavigation />
                {/* <OrderHistory /> */}
              </>
            );
          }}
        />
        <Route exact path="/select-address" component={SelectAddress} />
        <Route exact path="/payment-wpay/" component={Worldpay} />

        <Route
          exact
          path="/payment-wpay/rewards"
          component={RewardsAsPayment}
        />

        <Route exact path="/home/" component={HomepageLanding} />

        <Route exact path="/main/" component={Main} />

        <Route
          exact
          path={"/menu"}
          component={
            enableMenu === 1
              ? RestaurantHome
              : enableSL === 1
                ? RestaurantSL
                : Restaurant
          }
        />

        {enableMenu === 1 && (
          <Route
            exact
            path={["/menu/:category", "/menu/:category/:productName"]}
            component={RestaurantMenu}
          />
        )}
        {enableMenu !== 1 && (
          <Route
            exact
            path={["/menu/", "/menu/:productName"]}
            component={enableSL === 1 ? RestaurantSL : Restaurant}
          />
        )}

        {enableRevenueCenter !== 1 ? (
          <Route
            exact
            path={["/:categoryName", "/:categoryName/:productName"]}
            component={RestaurantCategory}
          />
        ) : (
          <Route exact path="/:revenueCenterName" component={WelcomeQr} />
        )}

        <Route
          path="/menu/:productName"
          component={enableSL === 1 ? ProductDetailsSL : ProductDetails}
        />

        <Route exact path="/map" component={DeliveryMap} />
        {/* <Route path="/dynamic/:page/:contentId" component={DynamicContent} /> */}
        <Route path="/order-status/:orderId" exact component={StatusScreen} />
        <Route>
          <Redirect to="/" />
        </Route>
        <Route
          exact
          path={["/menu/", "/menu/:productName"]}
          component={enableSL === 1 ? RestaurantSL : Restaurant}
        />

        <Route path="/:categoryName/:productName" component={ProductDetails} />

        <Route exact path="/favorites/" component={Favorites} />
      </Switch>

      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
}

export default App;
