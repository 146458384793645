import { useCallback, useEffect, useRef, useState } from "react";
import {
  AudioConfig,
  SpeechConfig,
  SpeechSynthesizer,
  SpeechRecognizer,
  PropertyId,
} from "microsoft-cognitiveservices-speech-sdk";

const useAzureSpeech = (defaultResponse) => {
  const [text, setText] = useState();
  const [readText, setReadText] = useState();
  const [response, setResponse] = useState();
  const [status, setStatus] = useState("off"); // Possible values: "on", "off", "idle"
  const [isSpeaking, setIsSpeaking] = useState(false);
  const recognizerRef = useRef(null);
  const idleTimerRef = useRef(null);
  const speechConfig = SpeechConfig.fromSubscription(
    "061979f756dc4e8c9280619317e3283f",
    "centralus"
  );

  const resetIdleTimer = useCallback(() => {
    if (idleTimerRef.current) {
      clearTimeout(idleTimerRef.current);
    }
    idleTimerRef.current = setTimeout(() => {
      recognizerRef.current?.stopContinuousRecognitionAsync(() => {
        recognizerRef.current.close();
        recognizerRef.current = null;
        setStatus("idle");
      });
    }, 60000); // 1 minute = 60,000 milliseconds
  }, []);

  const startContinuousRecognition = useCallback(() => {
    speechConfig.setProperty(
      PropertyId.SpeechServiceConnection_EndSilenceTimeoutMs,
      "10000"
    );
    const audioConfig = AudioConfig.fromDefaultMicrophoneInput();

    recognizerRef.current = new SpeechRecognizer(speechConfig, audioConfig);

    recognizerRef.current.startContinuousRecognitionAsync();
    setStatus("on");
    console.log("statr");
    resetIdleTimer();
    // Recognizing event is fired whenever a fragment of speech is recognized
    recognizerRef.current.recognizing = (s, e) => {
      resetIdleTimer();
      setReadText(e.result.text);
      console.log(`RECOGNIZING: Text=${e.result.text}`);
    };

    // Recognized event is fired when the recognizer has finalized the recognition
    recognizerRef.current.recognized = (s, e) => {
      resetIdleTimer();
      setText(e.result.text);
    };

    // Handle errors
    recognizerRef.current.canceled = (s, e) => {
      console.log(`CANCELED: Reason=${e.reason}`);
      recognizerRef.current.stopContinuousRecognitionAsync();
      setStatus("off");
    };
  }, []);

  const stopContinuousRecognition = useCallback(() => {
    recognizerRef.current?.stopContinuousRecognitionAsync(() => {
      recognizerRef.current.close();
      recognizerRef.current = null;
      setStatus("off");
    });

    if (idleTimerRef.current) {
      clearTimeout(idleTimerRef.current);
    }
  }, []);

  useEffect(() => {
    // Cleanup recognizer on component unmount
    return () => {
      stopContinuousRecognition();
    };
  }, [stopContinuousRecognition]);

  useEffect(() => {
    if (!response) return;
    stopContinuousRecognition();
    setText("");
    setReadText("");
    speechConfig.speechSynthesisVoiceName = "en-US-JennyNeural";
    const audioConfig = AudioConfig.fromDefaultSpeakerOutput();
    const synthesizer = new SpeechSynthesizer(speechConfig, audioConfig);
    synthesizer.speakTextAsync(
      response,
      (result) => {
        setStatus("idle");
        if (result.errorDetails) {
          console.error(result.errorDetails);
        } else {
          if (status === "processing") {
            setTimeout(
              () => {
                setResponse(""); // Reset the response to empty
                setIsSpeaking(false);
                startContinuousRecognition(); // Start listening again
              },
              Math.round(result.audioDuration / 10000)
            );
          }
        }
        synthesizer.close();
      },
      (err) => console.log(err, "Error during synthesis")
    );
  }, [response]);

  return [
    text,
    setText,
    response,
    setResponse,
    status,
    setStatus,
    startContinuousRecognition,
    stopContinuousRecognition,
    isSpeaking,
    setIsSpeaking,
    readText,
  ];
};

export default useAzureSpeech;
