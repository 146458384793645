import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";

const addressMap = process.env.REACT_APP_ADDRESS_MAP;
const restaurantName = process.env.REACT_APP_RESTAURANT_NAME;

export const AddressMap = () => {
  useEffect(() => {
    AOS.init({
      duration: 1200,
    });
  }, []);

  return (
    <div
      className="google-map-code whitespace"
      data-aos-easing="ease-in-out"
      data-aos-delay="0"
      data-aos="fade-bottom"
    >
      <iframe
        src={addressMap}
        width="100%"
        height="450"
        frameborder="0"
        style={{ border: 0 }}
        allowfullscreen=""
        aria-hidden="false"
        tabindex="0"
        title={`${restaurantName} | Address Map`}
        alt={`${restaurantName} | Google Map`}
      ></iframe>
    </div>
  );
};
