import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { MenuNavigation } from "../components/MenuNavigation";
import { Footer } from "../components/Footer";
import BlankSpace from "../components/BlankSpace";
import PhotoGalleryList from "../components/PhotoGalleryList";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Typography from "@material-ui/core/Typography";
import config from "../../../config";
import AOS from "aos";
import "aos/dist/aos.css";

const restaurantName = process.env.REACT_APP_RESTAURANT_NAME;
const siteMedSrc = process.env.REACT_APP_MEDIA_SRC;
const buttonText = process.env.REACT_APP_BUTTON_TEXT;
const textHighlight = process.env.REACT_APP_TEXT_HIGHLIGHT;
const restaurantURL = process.env.REACT_APP_SITE;

export const PhotoGallery = () => {
  const [galleryContent, setGalleryContent] = useState("");

  useEffect(() => {
    const galleryContentText = config.gallerycontent;

    setGalleryContent(galleryContentText);
  }, []);

  useEffect(() => {
    AOS.init({
      duration: 300,
    });
  }, []);

  return (
    <div
      className="PhotoGallery"
      data-aos-easing="ease-in-out"
      data-aos-delay="0"
      data-aos="fade-top"
    >
      <MenuNavigation />
      <HelmetProvider>
        <Helmet>
          <meta charSet="utf-8" />
          <title>{"Photo Gallery: Explore our Photo Gallery"}</title>
          <link rel="canonical" href={`${restaurantURL}photo-gallery`} />
          <meta name="description" content={`${galleryContent.description}`} />
          <meta name="keywords" content={`${galleryContent.keywords}`} />
          <meta
            property="og:title"
            content={`${restaurantName}: Explore our Photo Gallery`}
          />
          <meta
            property="og:description"
            content={`${galleryContent.description}`}
          />
          <meta
            property="twitter:title"
            content={`${restaurantName}: ${galleryContent.title}`}
          />
          <meta
            property="twitter:description"
            content={`${galleryContent.description}`}
          />
        </Helmet>
      </HelmetProvider>
      <div
        className="HeaderImg"
        data-aos-easing="ease-in-out"
        data-aos-delay="100"
        data-aos="fade-top"
        style={{
          backgroundImage: `url(${siteMedSrc}photo-gallery-bg.webp)`,
          color: buttonText,
        }}
      >
        <Container
          data-aos-easing="ease-in-out"
          data-aos-delay="200"
          data-aos="fade-top"
        >
          <Row>
            <col className="whitespace"></col>
            <Col xs={12}>
              <h1 className="center" style={{ color: textHighlight }}>
                Explore our Photo Gallery
              </h1>
            </Col>
          </Row>
        </Container>
      </div>
      <BlankSpace />
      <Container
        data-aos-easing="ease-in-out"
        data-aos-delay="300"
        data-aos="fade-top"
      >
        <Col xs={10} className="center">
          <Typography component="h2" variant="h6" className="title whitespace">
            {galleryContent.message}
          </Typography>
        </Col>
        <BlankSpace />
        <Row className="whitespace">
          <Col>
            <PhotoGalleryList />
          </Col>
        </Row>
      </Container>
      <BlankSpace />
      <Footer />
    </div>
  );
};
