import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { withRouter, useHistory, useParams } from "react-router-dom";
import { MenuNavigation } from "../components/MenuNavigation";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { FaHeart } from "react-icons/fa";
import axios from "axios";
import Swal from "sweetalert2";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { useCookies } from "react-cookie";
import config from "../../../config";
import AOS from "aos";
import "aos/dist/aos.css";

const serverId = process.env.REACT_APP_SERVER_ID;
const siteId = process.env.REACT_APP_SITE_ID;
const siteMedSrc = process.env.REACT_APP_MEDIA_SRC;
const restaurantName = process.env.REACT_APP_RESTAURANT_NAME;
const buttonColor = process.env.REACT_APP_BUTTON_COLOR;
const restaurantURL = process.env.REACT_APP_SITE

export const ChangePassword = () => {
  function Copyright(props) {
    return (
      <Typography variant="body2" color="white" align="center" {...props}>
        Made with <FaHeart style={{ color: "red" }} /> by <br />
        <Link color="inherit" href="https://servingintel.com">
          <img
            className="Logo"
            style={{ padding: "10px 0", width: "150px" }}
            src={siteMedSrc + "servingintel-logo-bw" + ".svg"}
            title="ServingIntel"
            alt="Point of Sale: Senior Living POS System"
            width="100%"
            height="100%"
            loading="lazy"
          />
        </Link>
      </Typography>
    );
  }

  useEffect(() => {
    const eloyaltyContentText = config.eloyaltycontent;

    seteloyaltyContent(eloyaltyContentText);
  }, []);

  const [eloyaltyContent, seteloyaltyContent] = useState("");

  const [cookieSIC, setCookieSIC, removeCookieSIC] = useCookies([
    "sic_email",
    "sic_user_id",
  ]);

  const history = useHistory();
  const theme = createTheme();
  // const [data, setData] = useState([]);
  const { id } = useParams();

  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");

  const handleUpdate = async () => {
    if (newPassword !== confirmPassword) {
      Swal.fire({
        icon: "error",
        title: "Sorry...",
        text: "Your new password and confirmed password do not match",
      });
      return;
    }

    if (newPassword.includes(" ")) {
      Swal.fire({
        icon: "error",
        title: "Sorry...",
        text: "Please set a new password that does not contain any spaces or blank characters",
      });
      return;
    }

    if (
      !currentPassword.trim() ||
      !newPassword.trim() ||
      !confirmPassword.trim()
    ) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Please provide all the required information",
      });
      return;
    }

    try {
      // SERVER PROXY FOR CORS PREFLIGHT ERROR
      const proxy = "https://web-production-d6e6.up.railway.app/";
      const endpoint =
        proxy +
        "https://ecommv3.servingintel.com/customer/update-profile-password-by-email/" +
        serverId +
        "/" +
        siteId +
        "/" +
        cookieSIC.sic_email;
      const res = await axios.post(
        proxy +
          "https://ecommv3.servingintel.com/customer/update-profile-password-by-email/" +
          serverId +
          "/" +
          siteId +
          "/" +
          cookieSIC.sic_email,
        { OldPassword: currentPassword, NewPassword: newPassword }
      );
      // console.log(res.data.status);
      if (res.data.status === 200) {
        setCurrentPassword("");
        setNewPassword("");
        setConfirmPassword("");
        setError("");
        Swal.fire({
          icon: "success",
          title: "Password has been updated!",
          text: "You'll be redirected to the home page",
          showConfirmButton: false,
          timer: 1500,
        }).then(() => {
          // Redirect to home page
          setTimeout(() => {
            history.push("/");
          }, 1500);
        });
      } else {
        if (currentPassword === newPassword) {
          Swal.fire({
            icon: "error",
            title: "Sorry...",
            text: "Your new password must be different from your current password",
          });
          return;
        }
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "You entered an incorrect old password",
          showConfirmButton: false,
          timer: 1500,
        });
        return;
      }
    } catch (error) {
      console.error("Failed due to: ", error);
      console.log("Current password is incorrect");
    }
  };

  // const checkPassword = async () => {
  //   const proxy = "https://cors-anywhere.herokuapp.com/"
  //   const endpoint = proxy + "https://ecommv3.servingintel.com/customer/update-profile-password-by-email/"+
  //       serverId +
  //       "/" +
  //       siteId +
  //       "/" +
  //       cookieSIC.sic_email;

  //   const response = await axios.post(endpoint, {
  //     Password: currentPassword,
  //   });

  //   if (response.status === 200) {
  //     console.log("old password is correct" + currentPassword)
  //     // The old password is correct.
  //     return true;
  //   } else {
  //     console.log("old password is incorrect")
  //     // The old password is incorrect.
  //     return false;
  //   }
  // }

  const paperStyle = {
    padding: 20,
    height: "auto",
    width: 320,
    margin: "20px auto",
  };
  const avatarStyle = { backgroundColor: "#1bbd7e" };
  const btnstyle = { margin: "4px 0" };

  useEffect(() => {
    AOS.init({
      duration: 800,
    });
  }, []);

  return (
    <div
      className="customBg"
      data-aos-easing="ease-in-out"
      data-aos-delay="0"
      data-aos="fade-top"
    >
      <MenuNavigation />
      <ThemeProvider theme={theme}>
        <HelmetProvider>
          <Helmet>
            <meta charSet="utf-8" />
            <title>{`${restaurantName}: ${eloyaltyContent.title}`}</title>
            <link rel="canonical" href={`${restaurantURL}change-password`} />
            <meta
              name="description"
              content={`${eloyaltyContent.description}`}
            />
            <meta name="keywords" content={`${eloyaltyContent.keywords}`} />
            <meta
              property="og:title"
              content={`${restaurantName}: ${eloyaltyContent.title}`}
            />
            <meta
              property="og:description"
              content={`${eloyaltyContent.description}`}
            />
            <meta
              property="twitter:title"
              content={`${restaurantName}: ${eloyaltyContent.title}`}
            />
            <meta
              property="twitter:description"
              content={`${eloyaltyContent.description}`}
            />
          </Helmet>
        </HelmetProvider>
        <Container
          component="main"
          className="eLoyaltyBg"
          data-aos-easing="ease-in-out"
          data-aos-delay="200"
          data-aos="fade-top"
          maxWidth="md"
          style={{ maxWidth: "850px", padding: "0", marginTop: "-50px" }}
        >
          <CssBaseline />
          <Box
            sx={{
              marginTop: 8,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              padding: "5px 20px",
              borderRadius: "5px",
            }}
            data-aos-delay="500"
            data-aos="fade-top"
          >
            <a href={`${restaurantURL}`} target="_self">
              <img
                className="Logo title"
                style={{ padding: "10px 0", width: "150px" }}
                src={siteMedSrc + "logo" + ".webp"}
                width="100%"
                height="100%"
                title={`${restaurantName} | ${eloyaltyContent.title}`}
                alt={`${restaurantName} | ${eloyaltyContent.description}`}
                loading="lazy"
              />
            </a>
            <Typography
              className="white"
              variant="h4"
              style={{ marginBottom: "10px", fontWeight: "550" }}
            >
              Change password
            </Typography>
            <div className="col-lg-12">
              <div className="white title center">
                <Typography variant="h5">
                  Enter a new password below to change your password
                </Typography>
              </div>
            </div>

            <Paper
              style={{
                boxShadow: "none",
                marginTop: "0",
                backgroundColor: "transparent",
                marginBottom: "1rem",
                width: "100%",
              }}
              data-aos-delay="600"
              data-aos="fade-top"
            >
              <React.Fragment>
                <Container
                  className="formBG"
                  style={{ padding: "0" }}
                  data-aos-delay="700"
                  data-aos="fade-top"
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                    variant="outlined"
                    padding="0"
                    data-aos-delay="800"
                    data-aos="fade-top"
                  >
                    <TextField
                      value={currentPassword}
                      margin="dense"
                      required
                      fullWidth
                      type={"password"}
                      id="changePassword"
                      label="Current password"
                      name="changePassword"
                      autoComplete="true"
                      onChange={(e) => {
                        setCurrentPassword(e.target.value);
                      }}
                    />
                    <TextField
                      value={newPassword}
                      margin="dense"
                      required
                      fullWidth
                      type={"password"}
                      id="newPassword"
                      label="New password"
                      name="newPassword"
                      autoComplete="true"
                      onChange={(e) => {
                        setNewPassword(e.target.value);
                      }}
                    />
                    <TextField
                      value={confirmPassword}
                      margin="dense"
                      required
                      fullWidth
                      type={"password"}
                      id="newPasswordConfirm"
                      label="Re-type new password"
                      name="newPasswordConfirm"
                      autoComplete="true"
                      onChange={(e) => {
                        setConfirmPassword(e.target.value);
                      }}
                    />
                    <Button
                      onClick={handleUpdate}
                      variant="contained"
                      fullWidth
                      sx={{ mt: 3, ml: 1, fontWeight: "550" }}
                    >
                      Submit new password
                    </Button>
                    <Button
                      fullWidth
                      onClick={() => {
                        history.push("/");
                      }}
                      size="large"
                      variant="contained"
                      style={{
                        fontWeight: "550",
                        backgroundColor: buttonColor,
                        borderColor: "none",
                        margin: ".5rem 0",
                      }}
                    >
                      Cancel
                    </Button>

                    {/* <Button
                            onClick={checkPassword}
                      >
                        Check Password
                      </Button> */}
                  </Box>
                </Container>
              </React.Fragment>
            </Paper>
          </Box>
          <Copyright />
        </Container>
      </ThemeProvider>
    </div>
  );
};

export default withRouter(ChangePassword);
