import React, { useState, useRef, useEffect } from "react";
import {
  Button,
  CssBaseline,
  TextField,
  Link,
  Paper,
  Box,
  Grid,
  Typography,
  createTheme,
  ThemeProvider,
  Fab,
  styled,
  AppBar,
  Toolbar,
  IconButton,
} from "@mui/material";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { useCookies } from "react-cookie";
import FontSizeChanger from "react-font-size-changer";
import alertify from "alertifyjs";
import "alertifyjs/build/css/alertify.css";
import packageJson from "../../../../../../package.json";
import BarTop from "../../BarTop";
import BarBottom from "../../BarBottom";
import FavoriteTwoToneIcon from "@mui/icons-material/FavoriteTwoTone";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { set } from "date-fns";
import { is } from "date-fns/locale";
import Keyboard from "react-simple-keyboard";
import "react-simple-keyboard/build/css/index.css";
import config from "../../../../../config";

const apiBaseURL = process.env.REACT_APP_API_BASE_URL;
const serverId = process.env.REACT_APP_SERVER_ID;
const siteId = process.env.REACT_APP_SITE_ID;
const storeId = process.env.REACT_APP_STORE_ID;
const loginCustomerNumber = process.env.REACT_APP_LOGIN_CUSTOMER_NUMBER;
const siteMedSrc = process.env.REACT_APP_MEDIA_SRC;
let enableNewUI = process.env.REACT_APP_NEWUI_LW;
const restaurantName = process.env.REACT_APP_RESTAURANT_NAME;
const logoWidth = process.env.REACT_APP_LOGO_WIDTH;
const backgroundColor = process.env.REACT_APP_BACKGROUND_COLOR;
const buttonColor = process.env.REACT_APP_BUTTON_COLOR;
const hoverColor = process.env.REACT_APP_TEXT_HIGHLIGHT;
const buttonText = process.env.REACT_APP_BUTTON_TEXT;
const enableKiosk = process.env.REACT_APP_ENABLE_EKIOSK;
const tagline = process.env.REACT_APP_RESTAURANT_TAGLINE;
const restaurantAddress = process.env.REACT_APP_RESTAURANT_ADDRESS || "";
const restaurantURL = process.env.REACT_APP_SITE;

function Copyright() {
  return (
    <Typography variant="body2" color="text.secondary" align="center">
      <br />
      Made with <FavoriteTwoToneIcon sx={{ color: "#820813" }} /> by
      <br />
      <Link color="inherit" target="_blank" href="https://servingintel.com/">
        <img
          className="silogo"
          src={`${siteMedSrc}si-logo.svg`}
          alt="ServingIntel"
        />
      </Link>{" "}
      {packageJson.version}
    </Typography>
  );
}

const StyledFab = styled(Fab)({
  position: "absolute",
  zIndex: 1,
  top: 0,
  left: 0,
  right: 0,
  margin: "0 auto",
  width: "360px",
  backgroundColor: "transparent !important",
  boxShadow: "none !important",
  textTransform: "none",
});

const theme = createTheme();

export const SignInSL = () => {
  const history = useHistory();
  const [newFontSize, setFontSize] = useState(16);
  const increaseFont = () => setFontSize(newFontSize + 2);
  const [cookie, setCookie, removeCookie] = useCookies();
  const [firstName, setFirstName] = useState(cookie.firstName || "");
  const [roomNumber, setRoomNumber] = useState(cookie.roomNumber || "");
  const [isTokenvalid, setIsTokenValid] = useState(false);
  const [keyboardVisible, setKeyboardVisible] = useState(false);
  const [numpadVisible, setNumpadVisible] = useState(false);
  const [layoutName, setLayoutName] = useState("default");
  const [layoutRoom, setLayoutRoom] = useState("default");
  const nameInputRef = useRef(null);
  const roomInputRef = useRef(null);

  // Add state to track caret positions
  const [caretPositionName, setCaretPositionName] = useState(null);
  const [caretPositionRoom, setCaretPositionRoom] = useState(null);

  // Function to handle clicks outside the TextField and Keyboard
  const handleClickOutside = (event) => {
    if (nameInputRef.current && !nameInputRef.current.contains(event.target)) {
      setKeyboardVisible(false);
    }
    if (roomInputRef.current && !roomInputRef.current.contains(event.target)) {
      setNumpadVisible(false);
    }
  };

  useEffect(() => {
    // Add event listener to handle clicks outside
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Clean up the event listener
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleOnKeyPress = (button) => {
    if (button === "{shift}" || button === "{lock}") {
      handleShift();
    } else if (button === "{enter}") {
      setKeyboardVisible(false);
    } else if (button === "{bksp}") {
      if (caretPositionName > 0) {
        setFirstName(
          (prev) =>
            prev.slice(0, caretPositionName - 1) + prev.slice(caretPositionName)
        );
        setCaretPositionName((pos) => pos - 1); // Correct state update for caret position
      }
    } else if (button === "{space}") {
      setFirstName((prev) => {
        const newValue =
          prev.slice(0, caretPositionName) +
          " " +
          prev.slice(caretPositionName); // Add a blank space
        return newValue;
      });
      setCaretPositionName((pos) => pos + 1); // Correct state update for caret position
    } else {
      setFirstName(
        (prev) =>
          prev.slice(0, caretPositionName) +
          button +
          prev.slice(caretPositionName)
      );
      setCaretPositionName((pos) => pos + 1); // Correct state update for caret position
    }
  };

  const handleOnNumPress = (button) => {
    if (button === "{shift}" || button === "{lock}") {
      handleShiftRoom();
    } else if (button === "{enter}") {
      setNumpadVisible(false);
    } else if (button === "{bksp}") {
      if (caretPositionRoom > 0) {
        setRoomNumber(
          (prev) =>
            prev.slice(0, caretPositionRoom - 1) + prev.slice(caretPositionRoom)
        );
        setCaretPositionRoom((pos) => pos - 1);
      }
    } else if (button === "{space}") {
      setRoomNumber((prev) => {
        const newValue =
          prev.slice(0, caretPositionRoom) +
          " " +
          prev.slice(caretPositionRoom); // Add a blank space
        setCaretPositionRoom(caretPositionRoom + 1);
        return newValue;
      });
    } else {
      setRoomNumber(
        (prev) =>
          prev.slice(0, caretPositionRoom) +
          button +
          prev.slice(caretPositionRoom)
      );
      setCaretPositionRoom((pos) => pos + 1);
    }
  };

  const handleShift = () => {
    setLayoutName(layoutName === "default" ? "shift" : "default");
  };

  const handleShiftRoom = () => {
    setLayoutRoom(layoutRoom === "default" ? "shift" : "default");
  };

  // Handle focus events and caret position updates
  const handleFocusName = () => {
    setKeyboardVisible(true);
    setTimeout(() => {
      const input = nameInputRef.current.querySelector("input");
      input.focus();
      setCaretPositionName(input.selectionStart);
    }, 0);
  };

  const handleFocusRoom = () => {
    setNumpadVisible(true);
    setTimeout(() => {
      const input = roomInputRef.current.querySelector("input");
      input.focus();
      setCaretPositionRoom(input.selectionStart);
    }, 0);
  };

  // Update caret position on input clicks
  const handleCaretPositionName = () => {
    const input = nameInputRef.current.querySelector("input");
    setCaretPositionName(input.selectionStart);
  };

  const handleCaretPositionRoom = () => {
    const input = roomInputRef.current.querySelector("input");
    setCaretPositionRoom(input.selectionStart);
  };

  useEffect(() => {
    if (cookie.firstName !== undefined && cookie.roomNumber !== undefined) {
      validateAccount();
    }
  }, []);

  const keyboardLayout = {
    default: [
      "q w e r t y u i o p {bksp}",
      "a s d f g h j k l {enter}",
      "{shift} z x c v b n m , . {shift}",
      "{space}",
    ],
    shift: [
      "Q W E R T Y U I O P {bksp}",
      "A S D F G H J K L {enter}",
      "{shift} Z X C V B N M , . {shift}",
      "{space}",
    ],
  };
  const numpadLayout = {
    default: [
      "1 2 3 4 5 6 7 8 9 0",
      "q w e r t y u i o p {bksp}",
      "a s d f g h j k l {enter}",
      "{shift} z x c v b n m , . {shift}",
      "{space}",
    ],
    shift: [
      "1 2 3 4 5 6 7 8 9 0",
      "Q W E R T Y U I O P {bksp}",
      "A S D F G H J K L {enter}",
      "{shift} Z X C V B N M , . {shift}",
      "{space}",
    ],
  };

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);

    if (
      params.get("firstName") &&
      params.get("roomNumber") &&
      params.get("token")
    ) {
      sessionStorage.setItem("isAdminLogin", 1);
      setFirstName(params.get("firstName"));
      setRoomNumber(params.get("roomNumber"));
      try {
        axios
          .get(
            `${apiBaseURL}/customer/validate-token/${serverId}/${siteId}/${params.get(
              "firstName"
            )}/${params.get("roomNumber")}/${params.get("token")}`
          )
          .then((response) => {
            if (parseInt(response.data) === 1) {
              setIsTokenValid(true);
            } else {
              setFirstName("");
              setRoomNumber("");
              alertify.alert(
                "Error",
                "The authentication has failed.",
                () => {}
              );
            }
          })
          .catch((error) => {
            setFirstName("");
            setRoomNumber("");
            console.log(error);
          });
      } catch (error) {
        // Handle error or return something specific if needed
      }
    } else {
      sessionStorage.setItem("isAdminLogin", 0);
    }
  }, []);

  useEffect(() => {
    if (isTokenvalid) {
      validateAccount();
    }
  }, [isTokenvalid]);

  const validateAccount = () => {
    const isAdminLogin = parseInt(sessionStorage.getItem("isAdminLogin")) === 1;
    const loginRoomNumberEnabled =
      parseInt(process.env.REACT_APP_LOGIN_ROOM_NUMBER) === 1;

    if (firstName === "" || roomNumber === "") {
      if (!isAdminLogin) {
        alertify.alert(
          "Error",
          "Invalid Account! Please input your account details",
          function () {}
        );
        return;
      }
    }

    // Determine the error message
    const errorMessage =
      loginRoomNumberEnabled || isAdminLogin
        ? "The authentication has failed."
        : "Invalid Account! Please check your account details";

    const url =
      loginCustomerNumber == 1
        ? `${apiBaseURL}/customer/login-customer-number/${serverId}/${siteId}/${firstName}/${roomNumber}`
        : `${apiBaseURL}/customer/login/${serverId}/${siteId}/${storeId}/${firstName}/${roomNumber}`;

    axios
      .get(url)
      .then((response) => {
        const customer = response.data;
        console.log(customer);

        if (!customer) {
          console.log("isTokenvalid", isTokenvalid);
          alertify.alert("Error", errorMessage, () => {});
          return;
        }

        if (
          customer.FirstName.trim().toLowerCase() ===
            firstName.trim().toLowerCase() &&
          (customer.Address1.trim().toLowerCase() ===
            roomNumber.trim().toLowerCase() ||
            customer.CustomerNumber == roomNumber)
        ) {
          if (loginRoomNumberEnabled || isAdminLogin) {
            sessionStorage.setItem("pinCodeOk", 1);
          }
          sessionStorage.setItem("customerDiscount", customer.Discount);
          sessionStorage.setItem("firstName", customer.FirstName);
          sessionStorage.setItem("lastName", customer.LastName);
          sessionStorage.setItem(
            "roomNumber",
            loginCustomerNumber == 1
              ? customer.CustomerNumber
              : customer.Address1
          );
          setCookie("firstName", customer.FirstName, { path: "/" });
          setCookie("roomNumber", customer.Address1, { path: "/" });
          setCookie("customerId", customer.CustomerID, { path: "/" });
          setCookie("customerNumber", customer.CustomerNumber, { path: "/" });
          setCookie("customerDetails", customer, { path: "/" });
          const people = [
            {
              firstname: customer.FirstName,
              room: customer.Address1,
              custid: customer.CustomerID,
              lastname: customer.LastName,
            },
          ];
          if (cookie.customerList !== undefined) {
            const addPeople = [...cookie.customerList, ...people];
            const output = [
              ...new Map(addPeople.map((o) => [o.custid, o])).values(),
            ];
            setCookie("customerList", output, { path: "/" });
          } else {
            setCookie("customerList", people, { path: "/" });
          }

          if (
            customer.SIC_WebPin ||
            parseInt(sessionStorage.getItem("isAdminLogin")) === 1
          ) {
            history.push("/logincode");
          } else {
            history.push("/UpdateCode");
          }
        }
      })
      .catch((error) => {
        console.error(error);
        alertify.alert("Error", errorMessage, () => {});
      });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    validateAccount();
  };

  const [signinSLContent, setsigninSLContent] = useState({
    description: "",
    keywords: "",
    title: "",
  });

  useEffect(() => {
    setsigninSLContent(
      config.signinslcontent || { description: "", keywords: "", title: "" }
    );
  }, []);

  return (
    <>
      <HelmetProvider>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Sign in: {restaurantName} </title>
          <link rel="canonical" href={`${restaurantURL}`} />
          <meta name="description" content={signinSLContent.description} />
          <meta name="keywords" content={signinSLContent.keywords} />
          <meta
            property="og:title"
            content={`${restaurantName}: ${restaurantAddress}`}
          />
          <meta
            property="og:description"
            content={signinSLContent.description}
          />
          <meta
            property="twitter:title"
            content={`${restaurantName}: ${signinSLContent.title}`}
          />
          <meta
            property="twitter:description"
            content={signinSLContent.description}
          />
        </Helmet>
      </HelmetProvider>
      <ThemeProvider theme={theme}>
        <Grid
          container
          component="main"
          justifyContent={enableKiosk === "1" ? "center" : null}
          alignItems={enableKiosk === "1" ? "center" : null}
          sx={{
            fontSize: enableKiosk === "0" ? `${newFontSize}px` : null,
            backgroundImage:
              enableKiosk === "1" ? `url(${siteMedSrc}signbg.webp)` : null,
            backgroundRepeat: enableKiosk === "1" ? "no-repeat" : "no-repeat",
            backgroundColor: (t) =>
              t.palette.mode === "light"
                ? t.palette.grey[50]
                : t.palette.grey[900],
            backgroundSize: enableKiosk === "1" ? "cover" : "cover",
            backgroundPosition: enableKiosk === "1" ? "left" : "left",
            left: enableKiosk === "1" ? "0" : "0",
            right: enableKiosk === "1" ? "0" : "0",
            minHeight: "100vh",
            height: enableKiosk === "0" ? "100vh" : null,
          }}
        >
          <CssBaseline />
          <Grid
            item
            xs={false}
            sm={enableKiosk === "1" ? false : 4}
            md={enableKiosk === "1" ? false : 7}
            sx={{
              backgroundRepeat: enableKiosk === "1" ? "no-repeat" : "no-repeat",
              backgroundColor: (t) =>
                t.palette.mode === "light"
                  ? t.palette.grey[50]
                  : t.palette.grey[900],
              backgroundSize: enableKiosk === "1" ? "cover" : "cover",
              backgroundPosition: enableKiosk === "1" ? "left" : "left",
              left: enableKiosk === "1" ? "0" : "0",
              right: enableKiosk === "1" ? "0" : "0",
              backgroundImage:
                enableKiosk === "0" ? `url(${siteMedSrc}signbg.webp)` : null,
            }}
          />
          <Grid
            item
            xs={enableKiosk === "1" ? 10 : 12}
            sm={enableKiosk === "1" ? 10 : 8}
            md={enableKiosk === "1" ? 10 : 5}
            component={Paper}
            elevation={6}
            square
          >
            <Box
              id="target"
              sx={{
                my: 8,
                mx: 4,
                display: "flex",
                flexDirection: "column",
              }}
            >
              {enableNewUI == 1 ? (
                <img
                  src={`${siteMedSrc}logo.webp`}
                  className="SL-logo02"
                  alt={`${restaurantName} logo`}
                />
              ) : enableNewUI == 0 ? (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <img
                    src={`${siteMedSrc}logo.webp`}
                    width={logoWidth}
                    alt={`${restaurantName} logo`}
                    style={{
                      transform: "scale(1.75)",
                      paddingTop: ".5rem",
                      paddingBottom: "1rem",
                    }}
                  />
                </div>
              ) : (
                <img
                  src={`${siteMedSrc}logo.webp`}
                  className="Default-logo"
                  alt={`${restaurantName} logo`}
                />
              )}
              <Typography
                className="content"
                sx={{
                  marginTop: 1,
                  textAlign: "center",
                  lineHeight: 1.1,
                  fontWeight: "bold",
                  fontSize:
                    enableKiosk === "0"
                      ? "1.5rem !important"
                      : "1.75rem !important",
                }}
              >
                <br />
                Hello. Please log into your account.
              </Typography>
              <Box
                className="content"
                component="form"
                noValidate
                onSubmit={handleSubmit}
                sx={{ mt: 1.5 }}
              >
                <div ref={nameInputRef}>
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="firstName"
                    label="First Name"
                    name="firstName"
                    autoComplete="first-name"
                    onFocus={handleFocusName}
                    variant="standard"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                    onClick={handleCaretPositionName} // Track caret position on click
                    InputLabelProps={{
                      style: {
                        fontSize: enableKiosk === "0" ? "1.25rem" : "1.5rem",
                      },
                    }}
                    InputProps={{
                      "aria-label": "firstName",
                      style: {
                        fontSize: enableKiosk === "0" ? "1.25rem" : "1.75rem",
                        marginTop: enableKiosk === "0" ? "1.125rem" : "1.5rem",
                      },
                    }}
                    sx={{ pt: 1.5 }}
                  />
                  {keyboardVisible && parseInt(enableKiosk) === 1 && (
                    <Keyboard
                      className="eKioskInput"
                      layout={keyboardLayout}
                      layoutName={layoutName}
                      onKeyPress={handleOnKeyPress}
                      inputName="firstName"
                    />
                  )}
                </div>
                <div ref={roomInputRef}>
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    name={
                      loginCustomerNumber == 1 ? "badgeNumber" : "roomNumber"
                    }
                    label={loginCustomerNumber == 1 ? "Badge #" : "Room #"}
                    type="text"
                    id={loginCustomerNumber == 1 ? "badgeNumber" : "roomNumber"}
                    autoComplete={
                      loginCustomerNumber == 1 ? "badge-number" : "room-number"
                    }
                    onFocus={handleFocusRoom}
                    variant="standard"
                    value={roomNumber}
                    onClick={handleCaretPositionRoom} // Track caret position on click
                    InputLabelProps={{
                      style: {
                        fontSize: enableKiosk === "0" ? "1.25rem" : "1.5rem",
                      },
                    }}
                    InputProps={{
                      "aria-label":
                        loginCustomerNumber === 1
                          ? "badgeNumber"
                          : "roomNumber",
                      style: {
                        fontSize: enableKiosk === "0" ? "1.25rem" : "1.75rem",
                        marginTop: enableKiosk === "0" ? "1.125rem" : "1.5rem",
                      },
                    }}
                    sx={{ pt: 1.5 }}
                    onChange={(e) => setRoomNumber(e.target.value)}
                  />
                  {numpadVisible && parseInt(enableKiosk) === 1 && (
                    <Keyboard
                      className="eKioskInput"
                      layout={numpadLayout}
                      layoutName={layoutRoom}
                      onKeyPress={handleOnNumPress}
                      inputName={
                        loginCustomerNumber == 1 ? "badgeNumber" : "roomNumber"
                      }
                    />
                  )}
                </div>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{
                    mt: 5,
                    mb: 2,
                    fontWeight: "600",
                    backgroundColor: buttonColor,
                    "&:hover": {
                      backgroundColor: hoverColor,
                    },
                  }}
                >
                  <Typography
                    className="content"
                    style={{
                      fontSize: enableKiosk === "0" ? "1.25rem" : "1.75rem",
                      fontWeight: "600",
                    }}
                  >
                    LOGIN
                  </Typography>
                </Button>
              </Box>
            </Box>
            {enableNewUI === "0" && enableKiosk === "0" ? <Copyright /> : null}
            {enableKiosk === "0" ? (
              <FontSizeChanger
                targets={["#target .content"]}
                onChange={(element, newValue, oldValue) => {
                  console.log(element, newValue, oldValue);
                }}
                options={{
                  stepSize: 2,
                  range: 5,
                }}
                customButtons={{
                  up: (
                    <AppBar
                      position="fixed"
                      sx={{
                        width: "90px",
                        boxShadow: 0,
                        top: "auto",
                        bottom: 237.5,
                        backgroundColor: "transparent",
                      }}
                    >
                      <Toolbar>
                        <Box sx={{ flexGrow: 1 }} />
                        <IconButton aria-label="add font size">
                          <Fab
                            color="primary"
                            aria-hidden="true"
                            sx={{ backgroundColor: "#3D5309" }}
                          >
                            <AddCircleOutlineIcon />
                          </Fab>
                        </IconButton>
                      </Toolbar>
                    </AppBar>
                  ),
                  down: (
                    <AppBar
                      position="fixed"
                      sx={{
                        width: "90px",
                        boxShadow: 0,
                        top: "auto",
                        bottom: 180,
                        backgroundColor: "transparent",
                      }}
                    >
                      <Toolbar>
                        <Box sx={{ flexGrow: 1 }} />
                        <IconButton>
                          <Fab
                            color="primary"
                            aria-hidden="true"
                            sx={{ backgroundColor: "#765F00" }}
                          >
                            <RemoveCircleOutlineIcon />
                          </Fab>
                        </IconButton>
                      </Toolbar>
                    </AppBar>
                  ),
                }}
              />
            ) : null}
          </Grid>
        </Grid>
        {enableNewUI === "1" ? (
          <>
            <BarTop />
            <BarBottom />
          </>
        ) : null}
      </ThemeProvider>
    </>
  );
};
